import React, { useState, useEffect, useRef } from 'react';
import logo from './logo.png';
import logoWhite from './logoWhite.png'
import './App.css';
import serversData from './servers.js';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { comingSoonServers } from './servers.js';

function App() {
  const [servers, setServers] = useState(serversData);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [displayedPlayers, setDisplayedPlayers] = useState(0);
  const [darkMode, setDarkMode] = useState(() => {
    if (typeof window !== 'undefined') {
      return window.matchMedia('(prefers-color-scheme: dark)').matches
    }
    return false
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setDarkMode(e.matches);
    };

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [darkMode]);

  useEffect(() => {
    const fetchPlayerCounts = async () => {
      const updatedServers = await Promise.all(
        servers.map(async (server) => {
          try {
            // Try primary API first (mcsrvstat.us)
            const response = await fetch(`https://api.mcsrvstat.us/3/${server.ip}`);
            if (response.status === 200) {
              const data = await response.json();
              if (data.online) {
                return { ...server, onlinePlayers: data.players.online };
              }
            }

            // If primary API fails, try secondary API (mcstatus.io)
            const backupResponse = await fetch(`https://api.mcstatus.io/v2/status/java/${server.ip}`);
            const backupData = await backupResponse.json();
            return { 
              ...server, 
              onlinePlayers: backupData.players.online 
            };
          } catch (error) {
            console.error(`Error fetching player count for ${server.name}:`, error);
            return { ...server, onlinePlayers: 0 };
          }
        })
      );
      setServers(updatedServers);
      const total = updatedServers.reduce((sum, server) => sum + server.onlinePlayers, 0);
      setTotalPlayers(total);
    };

    // Initial fetch
    fetchPlayerCounts();

    // Set up interval for periodic updates
    const intervalId = setInterval(fetchPlayerCounts, 15000); // 15 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [servers]); // Add servers to dependency array

  useEffect(() => {
    if (totalPlayers > 0) {
      const animationDuration = 2000; // 2 saniye
      const frameDuration = 1000 / 60; // 60 FPS
      const totalFrames = animationDuration / frameDuration;
      const incrementPerFrame = totalPlayers / totalFrames;

      let frame = 0;
      const counter = setInterval(() => {
        frame++;
        setDisplayedPlayers(Math.min(Math.floor(incrementPerFrame * frame), totalPlayers));

        if (frame >= totalFrames) {
          clearInterval(counter);
          setDisplayedPlayers(totalPlayers);
        }
      }, frameDuration);

      return () => clearInterval(counter);
    }
  }, [totalPlayers]);

  const serversRef = useRef(null);

  const scrollToServers = () => {
    serversRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  return (
    <div className="App">
      <div className="flex justify-center items-center p-4 bg-secondary-light dark:bg-secondary-dark">
        <img src={darkMode ? logoWhite : logo} alt="logo" className="max-w-[200px] h-auto" />
      </div>

      <div className="hero container">
        <div className="hero-bg">
          <h2>WHERE YOUR GAMING JOURNEY BEGINS!</h2>
          <h1>Join <span>{displayedPlayers}</span> Players Currently In-Game</h1>
          <button onClick={scrollToServers}>VIEW SERVERS</button>
        </div>
      </div>

      <div className="logo-carousel-container my-20">
        <Slider {...settings}>
          {servers.map((server, index) => (
            <div key={index} className="px-4">
              <img 
                src={require(`./${server.logo}`)}
                alt={server.name}
                className="h-16 w-auto mx-auto filter grayscale opacity-50 hover:grayscale-0 hover:opacity-100 transition-all duration-300"
              />
            </div>
          ))}
        </Slider>
      </div>

      <div className="aboutUs container">
        <h2>WHO ARE WE?</h2>
        <p>Our Minecraft network offers a variety of servers to cater to all types of players. Whether you enjoy survival, creative building, mini-games, or role-playing, we have a server for you. Our community is friendly and welcoming, and our staff is always ready to help. Join us today and become part of our growing family!</p>
      </div>

      <div id="servers" className="servers container" ref={serversRef}>
        <h2>OUR SERVERS</h2>

        <div className="server-cards">
          {servers.map((server, index) => (
            <div key={index} className="server-card">
              <div 
                className="server-img-bg"
                style={{ backgroundColor: server.logoBgColor }}
              >
                <img 
                  src={require(`/${server.logo}`)} 
                  alt={`${server.name} logo`}
                />
              </div>
              
              <div className="server-info">
                <div className="server-header">
                  <h3>{server.name}</h3>
                  <div className="server-social">
                    <a 
                      href={server.social.discord} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-discord"></i>
                    </a>
                    <a 
                      href={server.social.instagram} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
                
                <p>{server.description}</p>
                
                <div className="server-footer">
                  <a 
                    href={server.webSite} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    <button
                      style={{ 
                        backgroundColor: server.buttonColor,
                        color: server.textColor 
                      }}
                    >
                      Play Now
                    </button>
                  </a>
                  <div className="server-players">
                    <span>{server.onlinePlayers}</span>
                    Players
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="coming-soon container">
        <h2>COMING SOON</h2>
        <div className="coming-soon-cards">
          {comingSoonServers.map((server, index) => (
            <div key={index} className="coming-soon-card">
              <img 
                src={require(`./${server.logo}`)} 
                alt={`${server.name} logo`}
              />
              <h3>{server.name}</h3>
              <p>{server.description}</p>
              <div className="expected-date">
                Expected: {server.expectedDate}
              </div>
              <button
                style={{ 
                  backgroundColor: server.buttonColor,
                  color: server.textColor 
                }}
                disabled
              >
                Coming Soon
              </button>
            </div>
          ))}
        </div>
      </div>

      <footer className="bg-secondary-dark text-white py-10">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center gap-6 mb-8">
            <div className="footer-logo">
              <img src={logoWhite} alt="Logo" className="h-12" />
            </div>
            
            <ul className="flex gap-6">
              <li>
                <a href="/" className="hover:text-primary transition-colors">
                  Home
                </a>
              </li>
              <li>
                <a href="/servers" className="hover:text-primary transition-colors">
                  Servers
                </a>
              </li>
            </ul>
            
            <div className="flex gap-4">
              <a href="https://discord.gg/lime" 
                 className="text-white hover:text-primary transition-colors"
                 target="_blank" 
                 rel="noopener noreferrer">
                <i className="fab fa-discord text-2xl"></i>
              </a>
              {/* Diğer sosyal medya ikonları */}
            </div>
          </div>
          
          <div className="text-center pt-6 border-t border-gray-700">
            <p className="text-sm">
              &copy; 2024 LimeNW. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
